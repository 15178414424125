<template>
    <div class="productWrap">
        <div class="projectTop">
            <p class="productTitle">
                设备管理
            </p>
            <a-button type="primary" @click="gotoHome">
                返回主页
            </a-button>
        </div>
        <ul>
            <li style="display: flex; align-items: center">
                <a-select v-model="code" style="width: 200px; margin-right: 20px" @change="handleChange">
                    <a-select-option value="all">
                        全部产品
                    </a-select-option>
                    <a-select-option v-for="item in productList" :key="item.id" :value="item.code">
                        {{ item.productName }}
                    </a-select-option>
                </a-select>
                <div class="equipList">
                    <a-select v-model="equipStatus" style="width: 260px; margin: 0 20px">
                        <a-select-option value="3">
                            设备状态（全部）
                        </a-select-option>
                        <a-select-option value="0">
                            未激活
                        </a-select-option>
                        <a-select-option value="1">
                            在线
                        </a-select-option>
                        <a-select-option value="2">
                            离线
                        </a-select-option>
                    </a-select>
                    <a-select v-model="createMode" style="width: 200px; margin-right: 20px">
                        <a-select-option value="2">
                            创建方式（全部）
                        </a-select-option>
                        <a-select-option value="0">
                            人工注册
                        </a-select-option>
                        <a-select-option value="1">
                            自动注册
                        </a-select-option>
                    </a-select>
                    <a-input-search v-model="keyword" placeholder="请输入设备名称或id查询" :allow-clear="true" enter-button @search="onSearch" />
                </div>
            </li>
            <li style="display: flex; margin: 20px 0">
                <a-dropdown :trigger="['click']">
                    <a-button>
                        <a-icon type="setting" /> 自定义列表
                    </a-button>
                    <a-menu slot="overlay" style="padding: 20px; width: 400px">
                        <a-checkbox-group :default-value="['code', 'productName', 'status', 'imei', 'nodeType', 'source', 'createTime', 'latestOnlineTime']" @change="onChange">
                            <a-row>
                                <a-col :span="8">
                                    <a-checkbox value="code">
                                        设备code
                                    </a-checkbox>
                                </a-col>
                                <a-col :span="8">
                                    <a-checkbox value="productName">
                                        所属产品
                                    </a-checkbox>
                                </a-col>
                                <a-col :span="8">
                                    <a-checkbox value="status">
                                        设备状态
                                    </a-checkbox>
                                </a-col>
                                <a-col :span="8" style="margin: 12px 0">
                                    <a-checkbox value="imei">
                                        IMEI
                                    </a-checkbox>
                                </a-col>
                                <a-col :span="8" style="margin: 12px 0">
                                    <a-checkbox value="nodeType">
                                        节点类型
                                    </a-checkbox>
                                </a-col>
                                <a-col :span="8" style="margin: 12px 0">
                                    <a-checkbox value="source">
                                        创建方式
                                    </a-checkbox>
                                </a-col>
                                <a-col :span="8">
                                    <a-checkbox value="createTime">
                                        添加时间
                                    </a-checkbox>
                                </a-col>
                                <a-col :span="8">
                                    <a-checkbox value="latestOnlineTime">
                                        最近在线时间
                                    </a-checkbox>
                                </a-col>
                            </a-row>
                        </a-checkbox-group>
                    </a-menu>
                </a-dropdown>
            </li>
            <li style="display: flex; flex-direction: row-reverse">
                <a-button type="primary" @click="addEquipDialog = true">
                    添加设备
                </a-button>
                <a-button style="margin-right: 20px" @click="deleteEquipList">
                    批量移出
                </a-button>
            </li>
        </ul>
        <a-table style="margin-bottom: 20px"
                 :locale="{emptyText: '暂无数据'}"
                 :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                 table-layout="auto" :columns="columns" :pagination="false" row-key="id" :data-source="data">
            <template slot="status" slot-scope="text, record">
                <span>{{ record.status | statusTypeFilter }}</span>
            </template>
            <template slot="nodeType" slot-scope="text, record">
                <span>{{ record.nodeType | nodeTypeFilter }}</span>
            </template>
            <template slot="source" slot-scope="text, record">
                <span>{{ record.source | sourceTypeFilter }}</span>
            </template>
            <template slot="operation" slot-scope="text, record">
                <a href="javascript:void(0);" style="margin-right: 20px" @click="gotoDetails(record)">详情</a>
                <a href="javascript:void(0);" @click="onDelete(record)">移出项目</a>
            </template>
        </a-table>
        <Pagination v-show="isShowPage" :count="total" @handleChange="changePage" @handleChangeSize="changeSize" />
        <AddEquip :visible="addEquipDialog" @cancel="addEquipDialog = false" @onSubmit="onSure" />
    </div>
</template>

<script>
import Pagination from "@/components/Pagination"
import AddEquip from "@/views/components/AddEquip"
import { productList } from "@/api/product"
import { getEquipList, deleteEquip, deleteEquipList } from "@/api/equipment"
const columns = [
    {
        className: '',
        title: '设备code',
        dataIndex: 'code',
        key: 'id'
    },
    {
        className: '',
        title: '所属产品',
        dataIndex: 'productName',
        key: 'toProduct'
    },
    {
        className: '',
        title: '设备状态',
        dataIndex: 'status',
        scopedSlots: { customRender: 'status' }
    },
    {
        className: '',
        title: 'IMEI',
        dataIndex: 'imei',
        key: 'IMEI'
    },
    {
        className: '',
        title: '节点类型',
        dataIndex: 'nodeType',
        scopedSlots: { customRender: 'nodeType' }
    },
    {
        className: '',
        title: '创建方式',
        dataIndex: 'source',
        scopedSlots: { customRender: 'source' }
    },
    {
        className: '',
        title: '添加时间',
        dataIndex: 'createTime',
        key: 'addTime'
    },
    {
        className: '',
        title: '最近在线时间',
        dataIndex: 'latestOnlineTime',
        key: 'onlineTime'
    },
    {
        className: '',
        title: '操作',
        width: '180px',
        dataIndex: 'handle',
        scopedSlots: { customRender: 'operation' }
    },
]
export default {
    name: "Index",
    components: {
        Pagination,
        AddEquip
    },
    data() {
        return {
            // 当前分页码
            page: 1,
            // 每页数据条数
            size: 10,
            equipStatus: '3',
            createMode: '2',
            columns,
            data: [],
            // 当前编辑的设备id
            equipId: '',
            // 当前编辑的设备code
            equipCode: '',
            productList: [],
            // 设备列表多选数据集合
            selectedRowKeys: [],
            // 当前编辑的设备所属产品code
            editProductCode: '',
            // 添加设备弹窗搜索的地址内容
            searchVal: '',
            // 编辑设备弹窗搜索的地址内容
            editSearchVal: '',
            // 所属产品code
            code: 'all',
            // 添加设备弹窗中选中的所属产品code
            toCode: '',
            // 添加设备弹窗中的地址
            site: {},
            // 编辑设备弹窗中的地址
            editSite: {},
            // 列表数据总条数
            total: 0,
            // 用户输入的查询关键字
            keyword: '',
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
            // 添加设备的弹窗是否可见
            addEquipDialog: false,
            isShowPage: true
        }
    },
    created() {
        let id = this.$route.query.id || ''
        if(id) {
            this.code = id
        }
        this.addEquipDialog = this.$route.query.fromPage !== undefined
        this.getProductList()
        this.getList(this.page, this.size, '', id)
    },
    methods: {
        gotoHome() {
            this.$router.push('/home')
        },
        // 获取产品列表数据
        getProductList() {
            productList().then(res => {
                this.productList = res.data
            }).catch(error => {
                console.log(error)
            })
        },
        // 获取设备列表数据
        getList(page, size, name, code, statu, mode) {
            getEquipList(page, size, name, code, statu, mode).then(res => {
                let info = res.data
                this.data = info.records
                this.total = info.total
            }).catch(error => {
                console.log(error)
            })
        },
        // 选择分页
        changePage(page) {
            this.reloadList(this, page, this.size)
        },
        // 修改每页数据条数
        changeSize(size) {
            this.size = size
            this.reloadList(this, 1, size)
        },
        // 产品名称下拉选择
        handleChange() {
            this.keyword = ''
            this.reloadList(this, 1, this.size)
        },
        // 产品搜索
        onSearch() {
            this.reloadList(this, 1, this.size)
        },
        // 进入设备详情页
        gotoDetails(val) {
            this.$router.push(`/projectEquip-list/details?id=${val.id}`)
        },
        // 选择编辑设备弹窗中的地址
        editChooseSite(val) {
            this.editSearchVal = val.title
            this.editSite = {
                siteX: val.point.lat,
                siteY: val.point.lng,
                site: val.title
            }
        },
        // 更新列表数据
        reloadList(that, page, size) {
            let status = parseInt(that.equipStatus),
                mode = parseInt(that.createMode),
                code = that.code !== 'all' ? that.code : ''
            status = status !== 3 ? status : ''
            mode = mode !== 2 ? mode : ''
            that.getList(page, size, that.keyword.trim(), code, status, mode)
        },
        // 删除
        onDelete(val) {
            this.$confirm({
                title: '设备移出',
                content: '移出后可能影响场景联动等功能的正常使用，您确定要将该设备移出项目吗？',
                okText: '确定',
                centered: true,
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    deleteEquip(val.id).then(() => {
                        this.$message.success('删除设备成功！')
                        this.reloadList(this, 1, this.size)
                    }).catch(error => {
                        console.log(error)
                    })
                }
            })
        },
        // 批量删除
        deleteEquipList() {
            if(this.selectedRowKeys.length > 0) {
                this.$confirm({
                    title: '设备删除',
                    content: '确认删除这些设备吗',
                    okText: '确定',
                    centered: true,
                    okType: 'danger',
                    cancelText: '取消',
                    onOk: () => {
                        deleteEquipList(this.selectedRowKeys).then(() => {
                            this.$message.success('删除设备成功！')
                            this.reloadList(this, 1, this.size)
                        }).catch(error => {
                            console.log(error)
                        })
                    }
                })
            } else {
                this.$message.warn('请先选择要删除的设备!')
            }
        },
        // 设备列表中的多选事件
        onSelectChange(val) {
            this.selectedRowKeys = val
        },
        // 选择添加设备弹窗中的地址
        chooseSite(val) {
            this.searchVal = val.title
            this.site = {
                siteX: val.point.lat,
                siteY: val.point.lng,
                site: val.title
            }
        },
        addChooseType() {},
        // 自定义列表中多选事件
        onChange(val) {
            let arr = [],
                arr1 = []
            val.forEach((node) => {
                this.columns.forEach((item, index) => {
                    if(item.dataIndex !== node) {
                        arr.push(index)
                    } else {
                        arr1.push(index)
                    }
                })
            })
            arr.forEach((item) => {
                if (item !== 9) {
                    this.columns[item].className = 'hidden'
                }
            })
            arr1.forEach((item) => {
                this.columns[item].className = ''
            })
            let flag = arr1.length === 0
            this.isShowPage = !flag
            if(flag) {
                this.columns.forEach((item) => {
                    item.className = 'hidden'
                })
            } else {
                this.columns.forEach((item) => {
                    if(item.title === '操作') {
                        item.className = ''
                    }
                })
            }
        },
        // 确认添加设备操作
        onSure() {
            this.addEquipDialog = false
            this.$message.success('设备添加成功！')
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .hidden{
    display: none;
}
.productWrap {
    margin: 30px 40px;
    .projectTop {
        display: flex;
        justify-content: space-between;
        .productTitle {
            font-size: 20px;
            font-weight: bold;
        }
    }
}
::v-deep .ant-pagination {
    text-align: right;
}
::v-deep .ant-pagination-total-text {
    float: left;
}
::v-deep .ant-table-thead > tr > th,
::v-deep .ant-table-tbody > tr > td {
    padding: 16px 10px;
}
.addInfo {
    display: flex;
    align-items: center;
    margin-left: 40px;
    .downLoad {
        color: #1890ff;
        margin-left: 30px;
        cursor: pointer;
    }
}
.card-container {
    background: #f5f5f5;
    overflow: hidden;
    padding: 24px;
}
.card-container > .ant-tabs-card > .ant-tabs-content {
    height: 120px;
    margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane > .ant-table-wrapper{
    background: #fff;
    padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
    border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
    border-color: #fff;
    background: #fff;
}
.equipList {
    display: flex;
    li {
        margin-left: 60px;
        text-align: center;
    }
    p {
        margin-bottom: 0;
    }
    .equipNum {
        font-size: 16px;
        font-weight: bold;
        margin-top: 14px;
    }
}
.searchWrap {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .searchTitle {
        display: inline-block;
        width: 100px;
    }
}
.equipNameList {
    margin-bottom: 20px;
    ::v-deep .ant-table-thead > tr > th,
    ::v-deep .ant-table-tbody > tr > td {
        text-align: center;
    }
}
.selectedItem {
    position: absolute;
    bottom: 20px;
    left: 10px;
}
</style>
