var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "productWrap" },
    [
      _c(
        "div",
        { staticClass: "projectTop" },
        [
          _c("p", { staticClass: "productTitle" }, [_vm._v(" 设备管理 ")]),
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.gotoHome } },
            [_vm._v(" 返回主页 ")]
          ),
        ],
        1
      ),
      _c("ul", [
        _c(
          "li",
          { staticStyle: { display: "flex", "align-items": "center" } },
          [
            _c(
              "a-select",
              {
                staticStyle: { width: "200px", "margin-right": "20px" },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.code,
                  callback: function ($$v) {
                    _vm.code = $$v
                  },
                  expression: "code",
                },
              },
              [
                _c("a-select-option", { attrs: { value: "all" } }, [
                  _vm._v(" 全部产品 "),
                ]),
                _vm._l(_vm.productList, function (item) {
                  return _c(
                    "a-select-option",
                    { key: item.id, attrs: { value: item.code } },
                    [_vm._v(" " + _vm._s(item.productName) + " ")]
                  )
                }),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "equipList" },
              [
                _c(
                  "a-select",
                  {
                    staticStyle: { width: "260px", margin: "0 20px" },
                    model: {
                      value: _vm.equipStatus,
                      callback: function ($$v) {
                        _vm.equipStatus = $$v
                      },
                      expression: "equipStatus",
                    },
                  },
                  [
                    _c("a-select-option", { attrs: { value: "3" } }, [
                      _vm._v(" 设备状态（全部） "),
                    ]),
                    _c("a-select-option", { attrs: { value: "0" } }, [
                      _vm._v(" 未激活 "),
                    ]),
                    _c("a-select-option", { attrs: { value: "1" } }, [
                      _vm._v(" 在线 "),
                    ]),
                    _c("a-select-option", { attrs: { value: "2" } }, [
                      _vm._v(" 离线 "),
                    ]),
                  ],
                  1
                ),
                _c(
                  "a-select",
                  {
                    staticStyle: { width: "200px", "margin-right": "20px" },
                    model: {
                      value: _vm.createMode,
                      callback: function ($$v) {
                        _vm.createMode = $$v
                      },
                      expression: "createMode",
                    },
                  },
                  [
                    _c("a-select-option", { attrs: { value: "2" } }, [
                      _vm._v(" 创建方式（全部） "),
                    ]),
                    _c("a-select-option", { attrs: { value: "0" } }, [
                      _vm._v(" 人工注册 "),
                    ]),
                    _c("a-select-option", { attrs: { value: "1" } }, [
                      _vm._v(" 自动注册 "),
                    ]),
                  ],
                  1
                ),
                _c("a-input-search", {
                  attrs: {
                    placeholder: "请输入设备名称或id查询",
                    "allow-clear": true,
                    "enter-button": "",
                  },
                  on: { search: _vm.onSearch },
                  model: {
                    value: _vm.keyword,
                    callback: function ($$v) {
                      _vm.keyword = $$v
                    },
                    expression: "keyword",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "li",
          { staticStyle: { display: "flex", margin: "20px 0" } },
          [
            _c(
              "a-dropdown",
              { attrs: { trigger: ["click"] } },
              [
                _c(
                  "a-button",
                  [
                    _c("a-icon", { attrs: { type: "setting" } }),
                    _vm._v(" 自定义列表 "),
                  ],
                  1
                ),
                _c(
                  "a-menu",
                  {
                    staticStyle: { padding: "20px", width: "400px" },
                    attrs: { slot: "overlay" },
                    slot: "overlay",
                  },
                  [
                    _c(
                      "a-checkbox-group",
                      {
                        attrs: {
                          "default-value": [
                            "code",
                            "productName",
                            "status",
                            "imei",
                            "nodeType",
                            "source",
                            "createTime",
                            "latestOnlineTime",
                          ],
                        },
                        on: { change: _vm.onChange },
                      },
                      [
                        _c(
                          "a-row",
                          [
                            _c(
                              "a-col",
                              { attrs: { span: 8 } },
                              [
                                _c("a-checkbox", { attrs: { value: "code" } }, [
                                  _vm._v(" 设备code "),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "a-checkbox",
                                  { attrs: { value: "productName" } },
                                  [_vm._v(" 所属产品 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "a-checkbox",
                                  { attrs: { value: "status" } },
                                  [_vm._v(" 设备状态 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              {
                                staticStyle: { margin: "12px 0" },
                                attrs: { span: 8 },
                              },
                              [
                                _c("a-checkbox", { attrs: { value: "imei" } }, [
                                  _vm._v(" IMEI "),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              {
                                staticStyle: { margin: "12px 0" },
                                attrs: { span: 8 },
                              },
                              [
                                _c(
                                  "a-checkbox",
                                  { attrs: { value: "nodeType" } },
                                  [_vm._v(" 节点类型 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              {
                                staticStyle: { margin: "12px 0" },
                                attrs: { span: 8 },
                              },
                              [
                                _c(
                                  "a-checkbox",
                                  { attrs: { value: "source" } },
                                  [_vm._v(" 创建方式 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "a-checkbox",
                                  { attrs: { value: "createTime" } },
                                  [_vm._v(" 添加时间 ")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "a-col",
                              { attrs: { span: 8 } },
                              [
                                _c(
                                  "a-checkbox",
                                  { attrs: { value: "latestOnlineTime" } },
                                  [_vm._v(" 最近在线时间 ")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "li",
          { staticStyle: { display: "flex", "flex-direction": "row-reverse" } },
          [
            _c(
              "a-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    _vm.addEquipDialog = true
                  },
                },
              },
              [_vm._v(" 添加设备 ")]
            ),
            _c(
              "a-button",
              {
                staticStyle: { "margin-right": "20px" },
                on: { click: _vm.deleteEquipList },
              },
              [_vm._v(" 批量移出 ")]
            ),
          ],
          1
        ),
      ]),
      _c("a-table", {
        staticStyle: { "margin-bottom": "20px" },
        attrs: {
          locale: { emptyText: "暂无数据" },
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
          },
          "table-layout": "auto",
          columns: _vm.columns,
          pagination: false,
          "row-key": "id",
          "data-source": _vm.data,
        },
        scopedSlots: _vm._u([
          {
            key: "status",
            fn: function (text, record) {
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm._f("statusTypeFilter")(record.status))),
                ]),
              ]
            },
          },
          {
            key: "nodeType",
            fn: function (text, record) {
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm._f("nodeTypeFilter")(record.nodeType))),
                ]),
              ]
            },
          },
          {
            key: "source",
            fn: function (text, record) {
              return [
                _c("span", [
                  _vm._v(_vm._s(_vm._f("sourceTypeFilter")(record.source))),
                ]),
              ]
            },
          },
          {
            key: "operation",
            fn: function (text, record) {
              return [
                _c(
                  "a",
                  {
                    staticStyle: { "margin-right": "20px" },
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.gotoDetails(record)
                      },
                    },
                  },
                  [_vm._v("详情")]
                ),
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0);" },
                    on: {
                      click: function ($event) {
                        return _vm.onDelete(record)
                      },
                    },
                  },
                  [_vm._v("移出项目")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("Pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isShowPage,
            expression: "isShowPage",
          },
        ],
        attrs: { count: _vm.total },
        on: { handleChange: _vm.changePage, handleChangeSize: _vm.changeSize },
      }),
      _c("AddEquip", {
        attrs: { visible: _vm.addEquipDialog },
        on: {
          cancel: function ($event) {
            _vm.addEquipDialog = false
          },
          onSubmit: _vm.onSure,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }